export const ruTranslation = {
  components: {
    Cart: {
      cart: "Корзина",
      orderSentError: "Заказ НЕБЫЛ отправлен",
      cartIsEmpty: "Корзина пуста"
    },
    ContactForm: {
      name: "Имя",
      email: "Email",
      phone: "Телефон",
      send: "Отправить"
    },
    GoogleImages: {
      noImages: "Картинок не найдено"
    },
    OrderSendConfirmation: {
      successTitle: "Заказ был отправлен",
      errorTitle: "Заказ НЕБЫЛ отправлен",
      closeAndCleanCart: "Închideți și ștergeți coșul",
      successMessage: "Заказ был успешно отправлен менеджеру. В скором времени с вами свяжутся.",
      close: "Закрыть"
    },
    ProductList: {
      brand: "Марка",
      partNumber: "Номер запчасти",
      name: "Название",
      qty: "Количество",
      price: "Цена",
      add: "Добавить",
      remove: "Удалить",
      subTotal: "Subtotal",
      discount: "Скидка",
      total: "Итого",
      notFound: "Не было найдено результатов",
      qtyInput: "Количество",
      sum: "Сумма"
    },
    SearchForm: {
      brand: "Марка",
      partNumber: "Номер запчасти"
    }
  },
  pages: {
    SearchPage: {
      discountDescription: "Скидка 2% при заказе на 300-600€,\nСкидка 4% при заказе на 601-900€,\nСкидка 6% при заказе на 901-1200€,\nСкидка 8% при заказе на 1201-1500€,\nСкидка 10% при заказе более 1501€."
    }
  }
}