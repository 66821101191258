import useSWR from 'swr';
import { AspBrandsEnum, BrandsEnum } from "../typings/enums";
import { buildQueryParams } from "../libs/object";
import { GOOGLE_IMAGES_URL } from "../constants/common";

const useSearchGoogleImages = <T>(brand: BrandsEnum|AspBrandsEnum|string = '', search: string, perPage?: number) => {
  let url = null;
  if (search) {
    url = `${GOOGLE_IMAGES_URL}/google-images/?${buildQueryParams({search: `${[brand, search].join('+')}`, perPage})}`;
  }

  const { data, isLoading, error, mutate, isValidating } = useSWR<T[]>(url);

  return {
    data: data,
    isValidating,
    isLoading,
    error: error || null,
    mutate,
  };
}

export default useSearchGoogleImages;