export const roTranslation = {
  components: {
    Cart: {
      cart: "Coș",
      orderSentError: "Comanda NU a fost trimisă",
      cartIsEmpty: "Coșul este gol",
    },
    ContactForm: {
      name: "Nume",
      email: "Email",
      phone: "Telefon",
      send: "Tremite"
    },
    GoogleImages: {
      noImages: "Nu au fost găsite imagini"
    },
    OrderSendConfirmation: {
      successTitle: "Comanda a fost trimisă",
      errorTitle: "Comanda NU a fost trimisă",
      successMessage: "Comanda a fost trimisă cu succes către manager. Veți fi contactat în scurt timp.",
      closeAndCleanCart: "Închideți și ștergeți coșul",
      close: "Închide"
    },
    ProductList: {
      brand: "Marca",
      partNumber: "Part Number",
      name: "Nume",
      qty: "Cantitate",
      price: "Preț",
      add: "Adaugă",
      remove: "Șterge",
      subTotal: "Subtotal",
      discount: "Reducere",
      total: "Total",
      notFound: "Nu s-a găsit nimic",
      qtyInput: "Cantitate",
      sum: "Suma"
    },
    SearchForm: {
      brand: "Producător",
      partNumber: "Part Number"
    }
  },
  pages: {
    SearchPage: {
      discountDescription: "Reducere 2% pentru comenzi de 300-600€,\nReducere 4% pentru comenzi de 601-900€,\nReducere 6% pentru comenzi de 901-1200€,\nReducere 8% pentru comenzi de 1201-1500€,\nReducere 10% pentru comenzi peste 1501€."
    }
  }
}