import React, { useCallback, useContext, useEffect } from 'react';
import parse from 'html-react-parser';
import { Box, Stack, Typography } from "@mui/material";
import { mapStyles } from "../libs/styles";
import { AppContext } from '../contexts/DataProvider';
import { getSimplifiedCartContent } from "../libs/simplifiedCartContent";
import { MpIcon } from "../components/Icons";
import { useNavigate } from "react-router-dom";

export const PrintOrderPage: React.FC = (props) => {
  const [ctx, updateCtx] = useContext(AppContext);
  const navigate = useNavigate();

  const simplifiedOrder = getSimplifiedCartContent(ctx.contactForm, ctx.cart);
  const timestamp = new Date().toLocaleString();

  useEffect(() => {
    // window.print();
    // navigate(`/`);
    // window.history.back();
  }, []);

  return (
    <Box sx={styles.root}>
      <Stack sx={styles.contacts} direction="row" gap={2} alignItems='center'>
        <MpIcon sx={styles.mpIcon}/>
        <Stack direction="column">
          <Typography>{timestamp}</Typography>
          <Typography>
            {ctx.contactForm?.name || 'No name'} &lt; {ctx.contactForm?.email || 'no email'} &gt;, {ctx.contactForm?.phone || 'no phone'}
          </Typography>
        </Stack>
      </Stack>

      <Box sx={styles.parsed}>
        {parse(simplifiedOrder.html)}
      </Box>
    </Box>
  );
}

const styles = mapStyles({
  root: {
    '@media print': {
      fontSize: '12px',
      color: 'black',
      background: 'white',

      '@page': {
        // marginTop: '3cm',
      }
    }
  },
  contacts: {
    marginBottom: 1,

    '@media print': {
    //   position: 'fixed',
    //   top: '0',
    }
  },
  mpIcon: {
    fontSize: '52px',
  },
  parsed: {
    '& h1, & h2': {
      display: 'none',
    },
    '@media print': {
      '& table': {
        borderCollapse: 'collapse !important',
        width: '100%',

        '& thead th': {
          textAlign: 'left',
        },
        '& thead tr': {
          borderBottom: '1px solid grey',
        },
        '& tbody tr': {
          borderBottom: '1px solid grey',
        },
        '& tfoot': {
          marginTop: '1em',
          breakInside: 'auto',
        },
        '& td, & th': {
          padding: '0.5em 1em',
          '&:nth-last-of-type(1), &:nth-last-of-type(3)': {
            textAlign: 'right',
          },
          '&:nth-last-of-type(2)': {
            textAlign: 'center',
          },
        },
      },
    }
  },
});

export default PrintOrderPage;
