export const setLocalStorageItem = async <T = any>(key: string, value: T): Promise<void> => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorageItem = async <T = any>(key: string): Promise<T | null> => {
  const value = localStorage.getItem(key);
  if (!value) return null;

  try {
    return JSON.parse(value) as T;
  } catch (e) {
    return null;
  }
};

export const wipeLocalStorageItem = async (key: string): Promise<void> => {
  localStorage.removeItem(key);
};
