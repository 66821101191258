import DataProvider, { createContext } from './ContextProvider';
import { ProductModel } from "../typings/interfaces";
import { ContactFormModel } from "../typings/models";

export interface AppContextProps {
  isLoading: boolean;
  cart: ProductModel[];
  contactForm: ContactFormModel;
}

const defaultContext: AppContextProps = {
  isLoading: false,
  cart: [],
  contactForm: {
    name: '',
    email: '',
    phone: '',
  },
};

export const AppContext = createContext(defaultContext, 'AppContext');

export default DataProvider;
