import { SWRConfiguration } from 'swr';
import compare from '../libs/swrCompareResponse';
import fetcher from "../libs/fetcher";

export const SWR_REFRESH_INTERVAL = 0; // 60000;
export const SWR_DEDUPING_INTERVAL = 0; // 5000;

export default {
  fetcher,
  dedupingInterval: SWR_DEDUPING_INTERVAL,
  refreshInterval: SWR_REFRESH_INTERVAL,
  revalidateOnFocus: false,
  revalidateIfStale: false,
  revalidateOnReconnect: true,
  revalidateOnMount: true,
  compare,
} as SWRConfiguration;
