import React, { useContext, useState } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { mapStyles, mergeStyles } from "../libs/styles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AppContext } from "../contexts/DataProvider";
import ProductList from './ProductList';
import CloseIcon from '@mui/icons-material/Close';
import ContactForm from "./ContactForm";
import { ContactFormModel } from "../typings/models";
import useBreakpoints from "../hooks/useBreakpoints";
import { ProductModel } from "../typings/interfaces";
import { sendEmailWithOrder } from "../api/mailerApi";
import { useTranslation } from "react-i18next";

interface CartProps {
  error?: Nullable<string>;
  open?: boolean;
  onClose?: (state: boolean) => void;
  sx?: Style;
}

const OrderSendConfirmation: React.FC<CartProps> = (props) => {
  const { error, open, onClose, sx } = props;
  const [ctx, updateCtx] = useContext(AppContext);
  const {isMobile} = useBreakpoints();
  const { t } = useTranslation();

  if (!open) return null;

  const cleanCartAndClose = () => {
    updateCtx({cart: []});
    if (onClose) {
      onClose(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose && onClose(false)}
      maxWidth='xl'
      fullScreen={isMobile}
    >
      <DialogTitle sx={styles.dialogTitle}>
        { error ? t('components.OrderSendConfirmation.errorTitle') : t('components.OrderSendConfirmation.successTitle') }
        <IconButton aria-label="close" onClick={() => onClose && onClose(false)} sx={styles.close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        { error || t('components.OrderSendConfirmation.successMessage') }
      </DialogContent>
      <DialogActions>
        { !error && <Button onClick={() => cleanCartAndClose()}>{t('components.OrderSendConfirmation.closeAndCleanCart')}</Button> }
        <Button onClick={() => onClose && onClose(false)}>{t('components.OrderSendConfirmation.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderSendConfirmation;

const styles = mapStyles({
  root: {
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  close: {
    marginRight: -1,
  },
});
