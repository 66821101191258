import React, { useContext, useEffect, useState } from 'react';
import SearchForm from "../components/SearchForm";
import useSearchProducts from "../hooks/useSearchProducts";
import { ProductModel, SearchParams } from "../typings/interfaces";
import ProductList from "../components/ProductList";
import { Box, Typography } from "@mui/material";
import { mapStyles } from "../libs/styles";
import useQueryParams from "../hooks/useQueryParams";
import CartDialog from "../components/CartDialog";
import { AppContext } from '../contexts/DataProvider';
import { useTranslation } from "react-i18next";

export const SearchPage: React.FC = (props) => {
  const { brand: urlBrand, search: urlSearch, hasCart: urlHasCart = 'true'} = useQueryParams();
  const [searchParams, setSearchParams] = useState<Nullable<SearchParams>>(null)
  const {data, isLoading, mutate} = useSearchProducts<ProductModel>(searchParams?.brand ?? '', searchParams?.search ?? '');
  const [openCart, setOpenCart] = useState<boolean>(false);
  const [ctx, updateCtx] = useContext(AppContext);
  const { t, i18n } = useTranslation();

  const hasCart = urlHasCart === 'true';

  const handleSearch = (search: SearchParams) => {
    mutate();
    setSearchParams(search);
  }


  useEffect(() => {
    if (urlBrand && urlSearch) {
      setSearchParams({brand: urlBrand, search: urlSearch});
    }
  }, [])

  return (
    <Box sx={styles.root}>
      <SearchForm isLoading={isLoading} onSearch={handleSearch} hasCart={hasCart} onOpenCart={setOpenCart} />
      <ProductList searchParams={searchParams} items={data} isLoading={isLoading} hasCart={hasCart} />
      {false && !searchParams && (
        <Typography sx={styles.discountDescription}>
          {t('pages.SearchPage.discountDescription')}
        </Typography>
      )}
      {hasCart && <CartDialog open={openCart} onClose={setOpenCart} />}
      <Box data-iframe-height></Box>
    </Box>
  );
}

const styles = mapStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    maxHeight: 'var(--appHeight, 100vh)',
    overflow: 'hidden',
    width: '100%',
  },
  discountDescription: {
    textAlign: 'left',
    margin: 'auto',
    fontWeight: 'bold',
    whiteSpace: 'pre-line',
  }
});

export default SearchPage;
