export const enTranslation = {
  components: {
    Cart: {
      cart: "Cart",
      orderSentError: "The order WASN'T sent",
      cartIsEmpty: "Cart is empty",
    },
    ContactForm: {
      name: "Name",
      email: "Email",
      phone: "Phone",
      send: "Send"
    },
    GoogleImages: {
      noImages: "No images found"
    },
    OrderSendConfirmation: {
      successTitle: "The order was sent",
      errorTitle: "The order WASN'T sent",
      successMessage: "The order was sent successfully. The manager will contact you soon",
      closeAndCleanCart: "Close and clean the cart",
      close: "Close"
    },
    ProductList: {
      brand: "Brand",
      partNumber: "Part Number",
      name: "Name",
      qty: "Qty",
      price: "Price",
      add: "Add",
      remove: "Remove",
      subTotal: "Subtotal",
      discount: "Discount",
      total: "Total",
      notFound: "No results found",
      qtyInput: "Qty",
      sum: "Sum"
    },
    SearchForm: {
      brand: "Brand",
      partNumber: "Part Number"
    }
  },
  pages: {
    SearchPage: {
      discountDescription: "Discount 2% for orders between 300-600€,\nDiscount 4% for orders between 601-900€,\nDiscount 6% for orders between 901-1200€,\nDiscount 8% for orders between 1201-1500€,\nDiscount 10% for orders over 1501€."
    }
  }
}