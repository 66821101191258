import React, { useCallback, useContext, useRef, useState } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { mapStyles, mergeStyles } from "../libs/styles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AppContext } from "../contexts/DataProvider";
import ProductList from './ProductList';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import ContactForm from "./ContactForm";
import { ContactFormModel } from "../typings/models";
import useBreakpoints from "../hooks/useBreakpoints";
import { ProductModel } from "../typings/interfaces";
import { sendEmailWithOrder } from "../api/mailerApi";
import OrderSendConfirmation from "./OrderSendConfirmation";
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from "react-to-print";
import { getSimplifiedCartContent } from "../libs/simplifiedCartContent";
import { useNavigate } from "react-router-dom";
import PrintOrderPage from "../pages/PrintOrderPage";

interface CartProps {
  open?: boolean;
  onClose?: (state: boolean) => void;
  sx?: Style;
}

const CartDialog: React.FC<CartProps> = (props) => {
  const { open, onClose, sx } = props;
  const [ctx, updateCtx] = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSendConfirmation, setShowSendConfirmation] = useState<boolean>(false);
  const [confirmationError, setConfirmationError] = useState<Nullable<string>>('');
  const {isMobile} = useBreakpoints();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [printLoading, setPrintLoading] = useState(false);
  const printableComponentRef = useRef(null);
  const reactToPrintContent = useCallback(() => {
    return printableComponentRef.current;
  }, [printableComponentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Motoparts.md Cart",
    // onBeforeGetContent: handleOnBeforeGetContent,
    // onBeforePrint: handleBeforePrint,
    // onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
    pageStyle: `
      @media print {
        html {
          font-size: 10px;
        }
      }
    `,
  });

  // const handlePrint = () => {
  //     const popupWindow = window.open(`https://google.com`, '_blank', 'height=600,width=800');
  //     popupWindow?.print();
  //     popupWindow?.close();
  //     return true;
  // }

  // const handlePrint = () => {
  //   navigate(`/print-order`);
  // }


  if (!open) return null;

  const sendOrder = async (formData: ContactFormModel) => {
    console.log(formData);
    if (ctx.cart?.length < 1) {
      return;
    }
    setLoading(true);
    try {
      const res = await sendEmailWithOrder(formData, ctx.cart);
      console.log(res);
      setConfirmationError('');
    } catch (e) {
      setConfirmationError(t('components.Cart.orderSentError'));
    }
    setLoading(false);
    setShowSendConfirmation(true);
  };

  const handleFormChange = (formData: ContactFormModel) => {
    updateCtx({
      contactForm: formData,
    });
  }

  return (<>
    <Dialog
      open={open}
      onClose={() => onClose && onClose(false)}
      maxWidth='xl'
      fullScreen={isMobile}
      sx={styles.dialog}
    >
      <DialogTitle sx={mergeStyles(styles.dialogTitle, styles.notPrintable)}>
        {t('components.Cart.cart')}

        <IconButton aria-label="close" onClick={handlePrint} sx={styles.print}>
          <PrintIcon />
        </IconButton>
        <IconButton aria-label="close" onClick={() => onClose && onClose(false)} sx={styles.close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        {
          ctx.cart?.length > 0
            ? <ProductList items={ctx.cart} cartMode />
            : <Stack sx={styles.emptyCart}><Typography>{t('components.Cart.cartIsEmpty')}</Typography></Stack>
        }
      </DialogContent>
      <DialogActions sx={styles.dialogFooter}>
        {ctx.cart?.length > 0 && <ContactForm contacts={ctx.contactForm} onChange={handleFormChange} onSubmit={sendOrder} loading={loading} /> }
      </DialogActions>
    </Dialog>
    { showSendConfirmation && <OrderSendConfirmation error={confirmationError} open={showSendConfirmation} onClose={() => setShowSendConfirmation(false) }/> }
    <Box sx={styles.onlyPrintable} ref={printableComponentRef}><PrintOrderPage /></Box>
  </>);
};

export default CartDialog;

const styles = mapStyles({
  root: {},
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: '12px',
    py: 1,
  },
  dialogContent: {
    display: 'flex',
    paddingBottom: 1,
    px: '12px',
  },
  dialogFooter: {
    px: '12px',
    paddingBottom: '12px',
  },
  print: {
    marginLeft: 'auto',
  },
  close: {
    marginRight: -1,
  },
  emptyCart: {
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '300px',
    minHeight: '150px',
    margin: 'auto',
  },
  dialog: {
    '& .MuiDialog-paper': {
      '@media print': {
        flexDirection: 'column-reverse',
      },
    },
  },
  notPrintable: {
    '@media print': {
      display: 'none',
    },
  },
  onlyPrintable: {
    '@media screen': {
      display: 'none',
    },
  },
});
