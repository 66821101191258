import React from 'react';
import { Box, Typography } from "@mui/material";
import { mapStyles } from "../libs/styles";

export const NotFoundPage: React.FC = (props) => {

  return (
    <Box sx={styles.root}>
      <Typography variant='h1'>Page Not Found</Typography>
    </Box>
  );
}

const styles = mapStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    maxHeight: 'var(--appHeight, 100vh)',
    overflow: 'hidden',
    width: '100%',
  }
});

export default NotFoundPage;
