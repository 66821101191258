import React, { Suspense, useEffect } from 'react';
import { CssBaseline, ThemeProvider, SimplePaletteColorOptions } from '@mui/material';
import { SWRConfig } from 'swr';
import SearchPage from "./pages/SearchPage";
import swrConfig from './constants/swr';
import useQueryParams from "./hooks/useQueryParams";
import { deepMerge } from "./libs/object";
import { createTheme, ThemeOptions } from '@mui/material';
import { defaultThemeConfig } from "./constants/theme";
import DataProvider from './contexts/DataProvider';
import i18n, { InitOptions } from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { roTranslation } from "./i18next/roTranslation";
import { enTranslation } from "./i18next/enTranslation";
import { ruTranslation } from "./i18next/ruTranslation";
import "iframe-resizer/js/iframeResizer.contentWindow";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrintOrderPage from "./pages/PrintOrderPage";
import NotFoundPage from "./pages/NotFoundPage";

const i18nextConfig: InitOptions = {
  // the translations
  // (tip move them in a JSON file and import them,
  // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
  resources: {
    en: {
      translation: enTranslation,
    },
    ro: {
      translation: roTranslation,
    },
    ru: {
      translation: ruTranslation,
    },
  },
  // lng: "en", // if you're using a language detector, do not define the lng option
  fallbackLng: "en",
  supportedLngs: ['en','ro','ru'],
  load: 'languageOnly',
  defaultNS: 'translation',
  interpolation: {
    escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  // debug: true,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init(i18nextConfig);

const appHeight = (iframe: string) => {
  const height = iframe ? '100%' : `${window.innerHeight}px`;
  document.documentElement.style.setProperty('--appHeight', height);
};
export const App: React.FC = (props) => {

  // ?themeMode=light&mainColor=rgb(255,152,0)&backgroundColor=rgb(0,255,142)
  const { mainColor, backgroundColor, themeMode, lang, iframe } = useQueryParams();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.addEventListener('resize', () => appHeight(iframe));
  }, [])

  useEffect(() => {
    appHeight(iframe);
  }, [iframe])

  useEffect(() => {
    if (lang && Array.isArray(i18nextConfig.supportedLngs) && i18nextConfig.supportedLngs.includes(lang) && lang !== i18n.language) {
      console.log('languageChanged', lang);
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  const urlModifiedTheme: ThemeOptions = deepMerge(defaultThemeConfig, {
    palette: {
      ...(backgroundColor ? {
        background: {
          default: backgroundColor,
        },
      } : {}),
      ...(mainColor ? {
        primary: {
          main: mainColor
        }
      } : {}),
      ...(themeMode && ['dark','light'].includes(themeMode) ? {
        mode: themeMode
      } : {}),
    }
  } as ThemeOptions);

  return (
    <ThemeProvider theme={createTheme(urlModifiedTheme)}>
      <SWRConfig value={swrConfig}>
        <CssBaseline />
        <DataProvider>
          <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<SearchPage />} />
                <Route path="/print-order" element={<PrintOrderPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </DataProvider>
      </SWRConfig>
    </ThemeProvider>
  );
}

export default App;
