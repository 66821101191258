import React, { ComponentType, PropsWithChildren, ReactElement, useState } from 'react';
import { getLocalStorageItem, setLocalStorageItem } from '../services/storageService';
import { STATE_KEY } from '../constants/storageKeys';
import useApiEffect from '../hooks/useApiEffect';

type UpdateStateFn<T = any> = (newState: Partial<T>) => void;

const contextProviders: ComponentType<PropsWithChildren>[] = [];

export function createContext<T extends { isLoading: boolean }>(
  initialValue: T,
  contextName: string,
): React.Context<[T, UpdateStateFn<T>]> {
  const Context = React.createContext<[T, UpdateStateFn<T>]>([initialValue, () => null]);
  Context.displayName = contextName;

  const Provider: React.FC<PropsWithChildren> = ({ children }) => {
    const [state, setState] = useState<T>(initialValue);

    const updateState = async (newState: Partial<T> = {}) => {
      const merged = { ...state, ...newState };
      if (JSON.stringify(merged) === JSON.stringify(state)) {
        return;
      }
      setState(merged);
      await setLocalStorageItem(STATE_KEY, merged);
    };

    useApiEffect(async () => {
      const storageState = await getLocalStorageItem(STATE_KEY);

      if (storageState) {
        setState({ ...storageState, isLoading: false });
      } else {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    });

    return <Context.Provider value={[state, updateState]}>{children}</Context.Provider>;
  };

  contextProviders.push(Provider);
  return Context;
}

const ContextProvider = ({ children }: { children: ReactElement }) => {
  return contextProviders.reduceRight((acc, Provider) => {
    return <Provider>{acc}</Provider>;
  }, children);
};

export default ContextProvider;
