export function calculateDiscount(subtotal: number) {
  const discount: number =
    subtotal > 1501
    ? 10
    : subtotal > 1201
      ? 8
      : subtotal > 901
        ? 6
        : subtotal > 601
          ? 4
          : subtotal > 300
            ? 2
            : 0;

  const total = subtotal - (subtotal / 100 * discount);

  return {subtotal, discount, total};
}