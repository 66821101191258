import useSWR from 'swr';
import { AspBrandsEnum, BrandsEnum } from "../typings/enums";
import { buildQueryParams, isInstanceOfEnum } from "../libs/object";
import { API_URL } from "../constants/common";

const useSearchProducts = <T>(brand: BrandsEnum|AspBrandsEnum|string, search: string, perPage?: number) => {
  let url = null;
  if (brand && search.length >= 3) {
    if (isInstanceOfEnum(AspBrandsEnum, brand)) {
      url = `${API_URL}/asp/?${buildQueryParams({brands: brand, search, perPage})}`;
    } else if (isInstanceOfEnum(BrandsEnum, brand)) {
      url = `${API_URL}/${brand}/?${buildQueryParams({search, perPage})}`;
    }
  }

  const { data, isLoading, error, mutate, isValidating } = useSWR<T[]>(url);

  return {
    data: data,
    isValidating,
    isLoading,
    error: error || null,
    mutate,
  };
}

export default useSearchProducts;