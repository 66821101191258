import React, { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Stack, TextField } from '@mui/material';
import { mapStyles } from "../libs/styles";
import { AppContext } from "../contexts/DataProvider";
import { ContactFormModel } from "../typings/models";
import useMount from '../hooks/useMount';
import { errorUtil } from '../libs/formikError';
import LoadingButton from "@mui/lab/LoadingButton";
import useBreakpoints from "../hooks/useBreakpoints";
import { useTranslation } from "react-i18next";

const requiredMessage = 'Acest câmp este obligatoriu';

const validationSchema = Yup.object({
  name: Yup.string().required(requiredMessage),
  email: Yup.string().email().required(requiredMessage),
  phone: Yup.string().required(requiredMessage),
});

const FORM_DEFAULT_VALUES: ContactFormModel = {
  name: '',
  email: '',
  phone: '',
};

interface ContactFormProps {
  contacts: Nullable<ContactFormModel>;
  onChange?: (data: ContactFormModel) => void;
  onSubmit?: (data: ContactFormModel) => void;
  submitLabel?: string;
  loading?: boolean;
  sx?: Style;
}

const ContactForm: React.FC<ContactFormProps> = (props) => {
  const [ctx, updateCtx] = useContext(AppContext);
  const { contacts = ctx.contactForm, onChange, onSubmit, submitLabel, loading, sx } = props;

  const { values, handleSubmit, errors, touched, isValid, setValues, setFieldValue, setFieldTouched, validateForm } =
    useFormik({
      initialValues: FORM_DEFAULT_VALUES,
      validationSchema,
      onSubmit: onSubmit || ((data: ContactFormModel) => {}),
    });

  useEffect(() => {
    setValues(contacts ?? FORM_DEFAULT_VALUES);
  }, [contacts, setValues]);

  useMount(async () => {
    await validateForm();
  });

  const { isSmall } = useBreakpoints();
  const { t } = useTranslation();

  const errorHandling = errorUtil(touched, errors);

  const handleChange = async (name: string, value: any, touch?: boolean) => {
    await setFieldValue(name, value);
    if (touch) {
      await setFieldTouched(name, true);
    }
  };

  const handleBlur = async (name: string, touch?: boolean) => {
    await setFieldTouched(name, touch);
    if (onChange) {
      onChange(values);
    }
  }

  return (
    <Box sx={styles.form} component="form" onSubmit={handleSubmit}>
      <Stack gap={1} direction={isSmall ? 'column' : 'row'}>
        <TextField
          label={t('components.ContactForm.name')}
          value={values.name}
          onChange={(ev) => handleChange('name', ev.target.value)}
          onBlur={() => handleBlur('name', true)}
          error={errorHandling.isInvalid('name')}
          required
          sx={styles.input}
        />
        <TextField
          label={t('components.ContactForm.email')}
          value={values.email}
          onChange={(ev) => handleChange('email', ev.target.value)}
          onBlur={() => handleBlur('email', true)}
          error={errorHandling.isInvalid('email')}
          required
          sx={styles.input}
        />
        <TextField
          label={t('components.ContactForm.phone')}
          value={values.phone}
          onChange={(ev) => handleChange('phone', ev.target.value)}
          onBlur={() => handleBlur('phone', true)}
          error={errorHandling.isInvalid('phone')}
          required
          sx={styles.input}
        />

        <LoadingButton type="submit" variant="contained" disabled={!isValid} loading={loading} sx={styles.submit}>
          {submitLabel || t('components.ContactForm.send') }
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default ContactForm;

const styles = mapStyles({
  root: {
  },
  form: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  submit: {
    minWidth: 'min(25%, 100px)',
    '@media print': {
       display: 'none',
    },
  },
});
