import { ProductModel } from "../typings/interfaces";
import { ContactFormModel } from "../typings/models";
import { calculateDiscount } from "./calculateDiscount";

export const getSimplifiedCartContent = (
  form: Nullable<ContactFormModel>,
  cart: ProductModel[],
) => {
  const totalPriceInCart = cart.reduce((acc: number, item: ProductModel) => {
    acc = acc + ((+item.price ?? 0) * (item?.qty ?? 0));
    return acc;
  }, 0);

  const {subtotal, discount, total} = calculateDiscount(totalPriceInCart);

  const text = cart.map(
    ({brand, productId, name, price, qty, ...rest}, index) => {
      return `#${index+1}\nBrand: ${brand?.toUpperCase()}\nProduct: ${name}\nPart number: ${productId}\nPrice: ${price}€\nQty: ${qty}\n`;
    }
  ).join(`\n\n\n`) +
    `${discount 
      ? 
        `\n\n\nSubtotal: ${subtotal.toFixed(2)}€\n\n\n` + 
        `\n\n\nDiscount: -${discount}%\n\n\n` 
      : ``}` +
    `\n\n\nTotal: ${total.toFixed(2)}€\n\n\n`;

  const today = new Date().toLocaleString();

  const html = `
    <h1>New order: ${today}</h1>
    <h2>Client: <a href="mailto:${form?.email ?? '#'}">${form?.name ?? 'No name'}</a> - <a href="tel:${form?.phone ?? '#'}">${form?.phone ?? 'No phone'}</a></h2>
    <table style="border-spacing: 1em 0.5em; border-collapse: separate;">
    <thead>
      <tr>
        <th></th>
        <th>Brand:</th>
        <th>Part number:</th>
        <th>Product:</th>
        <th>Price:</th>
        <th>Qty:</th>
        <th>Sum:</th>
      </th>
    </thead>
    <tbody>
    ${cart.map(({brand, productId, name, price, qty, ...rest}, index) => `
      <tr>
        <td>#${index + 1}</td>
        <td>${brand?.toUpperCase()}</td>
        <td>${productId}</td>
        <td>${name}</td>
        <td>${price}€</td>
        <td>${qty}</td>
        <td>${(+(qty ?? 0) * (+(price ?? 0))).toFixed(2)}€</td>
      </tr>
      `
  ).join('\n')}
    </tbody>
    <tfoot>
      ${discount ? `
        <tr>
          <td colspan="4"></td>
          <td colspan="2" style="text-align: right;"><b>Subtotal:</b></td>
          <td><b>${subtotal.toFixed(2)}€</b></td>
        </tr>
        <tr>
          <td colspan="4"></td>
          <td colspan="2" style="text-align: right;"><b>Discount:</b></td>
          <td><b>-${discount}%</b></td>
        </tr>
      ` : ``}
      <tr>
        <td colspan="4"></td>
        <td colspan="2" style="text-align: right;"><b>Total:</b></td>
        <td><b>${total.toFixed(2)}€</b></td>
      </tr>
    </tfoot>
    </table>
  `;

  return {text, html};
}

