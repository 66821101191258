import { createTheme, ThemeOptions } from '@mui/material';

export const defaultThemeConfig: ThemeOptions = {
  palette: {
    primary: {
      main: '#b71c1c',
    },
    mode: 'light'
  },
  typography: {
    fontFamily: `'Open Sans', sans-serif`,
    h2: {
      fontSize: 24,
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          textTransform: 'inherit',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        // InputLabelProps: {
        //   shrink: false,
        // },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          scrollBehavior: 'smooth',
        },
      },
    },
  },
};
export const theme = createTheme(defaultThemeConfig);
