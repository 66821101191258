import { BaseCsvProductModel, ContactFormModel } from "../typings/models";
import axios, { AxiosResponse } from "axios";
import { MAILER_URL } from "../constants/common";
import { ProductModel } from "../typings/interfaces";
import { getSimplifiedCartContent } from "../libs/simplifiedCartContent";

export const sendEmailWithOrder = async <T = BaseCsvProductModel>(
  form: ContactFormModel,
  cart: ProductModel[],
): Promise<AxiosResponse<T>> => {

  const {text, html} = getSimplifiedCartContent(form, cart);
  
  return await axios.post<T>(`${MAILER_URL}/send-order`, {...form, text, html});
};