import React, { createRef, RefObject, useContext, useRef } from 'react';
import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Stack,
  IconButton, TableFooter, TableContainer, TextField, useMediaQuery
} from '@mui/material';
import { ProductModel, SearchParams } from "../typings/interfaces";
import NoResults from './NoResults';
import { ifStyles, mapStyles, mergeStyles } from "../libs/styles";
import { ResponsiveTable } from "./ResponsiveTable";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import theme from "../theme";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { AppContext } from "../contexts/DataProvider";
import { useTranslation } from "react-i18next";
import GoogleImagesPopover from './GoogleImagesPopover';
import { NaIcon } from "./Icons";
import { AspBrandsEnum } from "../typings/enums";
import { calculateDiscount } from "../libs/calculateDiscount";

interface ProductListProps {
  searchParams?: Nullable<SearchParams>;
  items?: ProductModel[];
  hasCart?: boolean;
  cartMode?: boolean;
  isLoading?: boolean;
  sx?: Style;
}

export const ProductList: React.FC<ProductListProps> = (props) => {
  const {items, searchParams, hasCart, cartMode, isLoading, sx} = props;
  const [ctx, updateCtx] = useContext(AppContext);
  const { t, i18n } = useTranslation();

  const qtyInputRefs = useRef([]);
  qtyInputRefs.current = (items ?? []).map((element, i) => qtyInputRefs.current[i] ?? createRef());

  const addToCart = (product: ProductModel, qty?: number) => {
    console.log('addToCart', product, qty);
    updateCtx({
      cart: [
        ...(ctx.cart ?? []),
        {
          ...product,
          qty: +(qty ?? 1),
          brand: searchParams?.brand,
        }
      ]
    });
  };
  const removeFromCart = (index: number) => {
    console.log('removeFromCart', index);
    const newCart = JSON.parse(JSON.stringify(ctx.cart));
    newCart.splice(index, 1);
    updateCtx({
      cart: newCart,
    });
  };

  const changeQty = (index: number, qty: number = 0) => {
    const newCart = JSON.parse(JSON.stringify(ctx.cart));
    let newQty = +(newCart[index].qty ?? 0) + qty;
    if (newQty < 1) {
      newQty = 1;
    }
    newCart[index].qty = newQty;
    updateCtx({
      cart: newCart,
    });
  }

  const subtotal: number = ctx.cart.reduce((acc: number, item: ProductModel) => {
    acc = acc + ((+item.price ?? 0) * (+(item?.qty ?? 0)));
    return acc;
  }, 0);

  const {discount, total} = calculateDiscount(subtotal);

  const footerRows = <>
    { discount > 0 && <>
        <TableRow>
            <TableCell colSpan={2} />
            <TableCell colSpan={3}><Typography textAlign='right'>{t('components.ProductList.subTotal')}:</Typography></TableCell>
            <TableCell><Typography>{subtotal.toFixed(2)}€</Typography></TableCell>
            <TableCell sx={styles.notPrintable}/>
        </TableRow>
        <TableRow>
            <TableCell colSpan={2} />
            <TableCell colSpan={3}><Typography textAlign='right'>{t('components.ProductList.discount')}:</Typography></TableCell>
            <TableCell><Typography>-{discount}%</Typography></TableCell>
            <TableCell sx={styles.notPrintable}/>
        </TableRow>
    </>
    }
    <TableRow>
      <TableCell colSpan={2} />
      <TableCell colSpan={3}><Typography textAlign='right'>{t('components.ProductList.total')}:</Typography></TableCell>
      <TableCell><Typography>{total.toFixed(2)}€</Typography></TableCell>
      <TableCell sx={styles.notPrintable}/>
    </TableRow>
  </>;

  return (
    <Box sx={mergeStyles(styles.root, sx)}>
      {isLoading && <LinearProgress sx={styles.loading}/>}

      {
        !isLoading &&
        items &&
        items.length > 0 && (
          <ResponsiveTable sx={mergeStyles(styles.responsiveTableContainer, ifStyles(i18n.language === 'ru', styles.longMobileLabels))}>
            <TableContainer sx={styles.tableContainer}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    { cartMode &&
                      <TableCell><Typography>{t('components.ProductList.brand')}</Typography></TableCell>
                    }
                    <TableCell><Typography>{t('components.ProductList.partNumber')}</Typography></TableCell>
                    <TableCell><Typography>{t('components.ProductList.name')}</Typography></TableCell>
                    <TableCell><Typography>{t('components.ProductList.price')}</Typography></TableCell>
                    <TableCell><Typography>{t('components.ProductList.qty')}</Typography></TableCell>
                    { cartMode && <TableCell><Typography>{t('components.ProductList.sum')}</Typography></TableCell> }
                    { !cartMode && hasCart && <TableCell><Typography>{t('components.ProductList.add')}</Typography></TableCell> }
                    { cartMode &&
                      <TableCell sx={styles.notPrintable}><Typography></Typography></TableCell>
                    }
                  </TableRow>
                </TableHead>
                <TableBody>

                {items.map((product: ProductModel, index) => {
                  let thisItemInCart = ctx.cart.find((i: ProductModel) => i.productId === product.productId);
                  let brand = product.brand ?? searchParams?.brand;

                  return (<TableRow key={index}>
                    { cartMode &&
                      <TableCell data-label={t('components.ProductList.brand')}>
                          <Typography sx={styles.brand}>{product.brand?.toUpperCase()}</Typography>
                      </TableCell>
                    }
                    <TableCell data-label={t('components.ProductList.partNumber')}>
                      <Stack direction='row' gap='12px' alignItems='center'>
                        <GoogleImagesPopover brand={brand} partNumber={product.productId} sx={styles.notPrintable} />
                        <Stack direction='row' gap='12px' alignItems='center' sx={styles.partNumberInnerWrapper}>
                          <Typography sx={mergeStyles(styles.productId, ifStyles(!!product.newProductId, styles.oldPartNumber))}>
                            {product.productId}
                            {!cartMode && searchParams?.search !== product.productId && !Object.values(AspBrandsEnum).includes(brand as AspBrandsEnum) && <FiberNewIcon sx={styles.productReplacementIcon}/>}
                          </Typography>
                          {!!product.newProductId && <>
                            <ArrowForwardIcon sx={styles.partNumberReplacementArrow} />
                            <Typography sx={styles.newProductId}>
                              {product?.newProductId}
                              <FiberNewIcon sx={styles.productReplacementIcon}/>
                            </Typography>
                          </>}
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell data-label={t('components.ProductList.name')}>
                      <Typography sx={styles.name}>{product.name}</Typography>
                    </TableCell>
                    <TableCell data-label={t('components.ProductList.price')}>
                      <Typography sx={styles.price}>{product.price}€</Typography>
                    </TableCell>
                    <TableCell data-label={t('components.ProductList.qty')}>
                      {cartMode
                        ?
                        <Stack gap='6px' direction='row' alignItems='center'>
                          <IconButton onClick={() => changeQty(index, -1)} sx={styles.notPrintable}><RemoveCircleOutlineIcon/></IconButton>
                          {product.qty}
                          <IconButton onClick={() => changeQty(index, 1)} sx={styles.notPrintable}><AddCircleOutlineIcon/></IconButton>
                        </Stack>
                        :
                        <TextField
                          inputRef={qtyInputRefs.current[index]}
                          defaultValue={!!thisItemInCart ? thisItemInCart.qty : 1}
                          type='number'
                          sx={styles.qtyInput}
                          size='small'
                          disabled={!!thisItemInCart || !!product.newProductId}
                          inputProps={{
                            min: 1,
                            max: 1000,
                            step: 1,
                          }}
                        />
                      }
                    </TableCell>
                    { cartMode &&
                      <TableCell data-label={t('components.ProductList.sum')}>
                        <Typography sx={styles.sum}>{((product.qty??0) * (+(product.price??0))).toFixed(2)}€</Typography>
                      </TableCell>
                    }
                    { !cartMode && hasCart &&
                      <TableCell data-label={t('components.ProductList.add')}>
                        {!product.newProductId
                          ? !!thisItemInCart
                            ? <IconButton disabled><DoneIcon /></IconButton>
                            : <IconButton onClick={() => addToCart(product, (qtyInputRefs.current[index] as RefObject<any>)?.current?.value)}><ShoppingCartIcon /></IconButton>
                          : <IconButton disabled><NaIcon /></IconButton>
                        }
                      </TableCell>
                    }
                    { cartMode &&
                      <TableCell data-label={t('components.ProductList.remove')} sx={styles.notPrintable}>
                        <IconButton onClick={() => removeFromCart(index)}><DeleteIcon /></IconButton>
                      </TableCell>
                    }
                  </TableRow>
                )})}
                  {/*{ cartMode && footerRows }*/}
                </TableBody>
                { cartMode &&
                  <TableFooter sx={mergeStyles(styles.tfoot)}>
                    {footerRows}
                  </TableFooter>
                }
              </Table>
            </TableContainer>
          </ResponsiveTable>
      )}
      <NoResults hide={!items || items?.length > 0 || isLoading} message={t('components.ProductList.notFound')} sx={styles.noResults} />
    </Box>
  );
};

const styles = mapStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflow: 'auto',
    minHeight: '36px',
    width: '100%',

    '& .MuiTableRow-root': {
      // backgroundColor: theme.palette.background.default,
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    '& .MuiTableHead-root .MuiTypography-root': {
      fontWeight: 'bold',
    },
    '& .MuiTableCell-root': {
      padding: '1em',
      '@media print': {
        padding: '0.75em',
      }
    },
    '& .MuiTableFooter-root .MuiTypography-root': {
      fontWeight: 'bold',
      fontSize: '1rem',
    },
    '@media print': {
    }
  },
  responsiveTableContainer: {
    overflow: 'initial',
  },
  tableContainer: {
    overflow: 'initial',
  },
  longMobileLabels: {
    '--labelWidth': '140px',
  },
  loading: {
    margin: '24px 12px 0',
  },
  brand: {

  },
  productId: {
    display: 'flex',
  },
  oldPartNumber: {
    textDecoration: 'line-through',
    opacity: '0.7',
  },
  partNumberReplacementArrow: {
    [theme.breakpoints.down('sm')]: {
      transform: 'rotate(90deg)',
    }
  },
  partNumberInnerWrapper: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 0,
    }
  },
  newProductId: {
    display: 'flex',
  },
  name: {

  },
  price: {

  },
  sum: {

  },
  noResults: {
    margin: '12px 0',
    fontSize: '20px',
  },
  qtyInput: {
    width: "72px",
    textAlign: 'center',
  },
  productReplacementIcon: {
    fontSize: '1em',
    marginLeft: '0.5em',
    position: 'relative',
    top: '-0.25em',

    [theme.breakpoints.down('sm')]: {
      marginRight: '-1.5em',
    },
  },
  tfoot: {
    '@media print': {
      // breakInside: 'avoid-page',
    }
  },
  notPrintable: {
    '@media print': {
      display: 'none',
    },
  },
  onlyPrintable: {
    '@media screen': {
      display: 'none',
    },
  },
});

export default ProductList;
